@import "src/styles/mixins.scss"; @import "src/styles/variables.scss"; @import "src/styles/media.scss";
.button_action {
  padding: 2px 12px;
  font-weight: 700;
  border-radius: 8px;
  position: relative;
  border: $border-btn-active;
  background: $radial-gradient-btn-active;
  color: white;
  text-shadow: $border-btn-text-disabled;
  &::after {
    content: "";
    position: absolute;
    bottom: -1px;
    left:0;
    width: 100%;
    height: 5px;
    z-index: 9;
    background: $btn-background-enabled-color;
    border: 1px solid $btn-background-enabled-beffore;
    border-radius: 4px 4px 120px 120px;
  }
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
    border: $border-blue;
    background: $radial-gradient-blue;
    color: $color-text-btn-disabled;
    text-shadow: $border-btn-text-disabled;
    &::after {
        content: "";
        position: absolute;
        bottom: -1px;
        left:0;
        width: 100%;
        height: 5px;
        z-index: 9;
        background: #5A6B75;
        border: 1px solid #282941;
        border-radius: 4px 4px 120px 120px;
      }
  }
}
