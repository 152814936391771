@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
  height: 100vh;
  color: white;
  overflow: hidden;
  font-optical-sizing: auto;
  font-style: normal;
}

* {
  font-family: "Montserrat", sans-serif;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

.Toastify__toast {
  min-height: 40px;
  height: 40px;
  padding: 4px 8px;
}

.Toastify__toast-body {
  padding: 0;
  margin: 0;
}

.Toastify__toast-icon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  object-fit: contain;
}

.Toastify__toast-container {
  max-height: 40px;
}

.Toastify__spinner {
  width: 16px;
  height: 16px;
}