@import "src/styles/mixins.scss"; @import "src/styles/variables.scss"; @import "src/styles/media.scss";
.selector {
  &__wrapper {
    position: relative;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  &__container {
    position: relative;
    z-index: 25;
    @include all-center-flex;

    gap: 15px;
    justify-content: flex-end;
    background: radial-gradient(
      110.97% 50% at 50% 50%,
      #681c0b 0%,
      #c95f28 100%
    );
    color: $color-text-lang;
    border-radius: 8px;

    &__options {
      max-height: 210px;
      overflow: scroll;
      @include no-scrollbar;
      position: absolute;
      transition: 0.3s linear all;
      transform: translate(-50%, -50%);
      top: 425%;
      right: -15%;
      @media (min-width:400px) {
        right: -13%;
      }
      @media (min-width:420px) {
        right: -14%;
      }
      @media (min-width:470px) {
        right: -10%;
      }
       @media (min-width:550px) {
        right: -8%;
      }
      z-index: 9;
      background-color: $color-bg-options;
      border-radius: 8px;
      border: 1px solid $text-lighten;
      @include all-center-flex;
      flex-direction: column;
      padding: 5px 10px;
      gap: 8px;
      padding-top: 80px;
    }
  }

  &__text {
    text-shadow: $border-text-gold;
  }

  &__choose__container {

    @include all-center-flex;
    padding: 2px 5px;
    width: 85px;
    position: relative;
    gap: 5px;
    border-radius: 8px;
    border: 1px solid $border-text-color-gold;
    & img{
      margin-bottom: 2px;
    }
    &::after {
    
      content: "";
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 5px;
      z-index: 9;
      background: $color-bg-options;
      border: 1px solid $border-text-color-gold;
      border-radius: 4px 4px 120px 120px;
    }
  }
}

.rotate {
  transform: rotate(180deg);
}

.card {
  &__wrapper {
    @include all-center-flex;
    width: 100%;
    text-align: start;
    color: white;
    text-shadow: $border-text-gold;
    background-color: $color-bg-light-options;
    padding: 2px 5px;
    border: 1px solid $text-lighten;
    border-radius: 8px;

  }
}
