@import "src/styles/mixins.scss"; @import "src/styles/variables.scss"; @import "src/styles/media.scss";
.container {
  position: absolute;
  z-index: 3000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  @include all-center-flex;
 
}

.modal {
  @include all-center-flex;
  position: relative;

  &__title {
    position: absolute;
    padding: 15px;
    text-wrap: nowrap;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 1.7rem;
    text-shadow: -1.5px -1.5px 0 $border-text-color-gold,
      1.5px -1.5px 0 $border-text-color-gold,
      -1.5px 1.5px 0 $border-text-color-gold,
      1.5px 1.5px 0 $border-text-color-gold;
    @include background-image-settings(
      "/assets/popup/popup-background-title.png"
    );
  }

  &__bonus {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 10px;
    z-index: 2;
    left: 30px;
    @include all-center-flex;
    flex-direction: column;
    transform: rotate(335deg);
  }

  &__text__error {
    color: #de2830;
    font-size: 0.8rem;
    font-weight: 700;
  }

  &__value {
    &__text {
      font-size: 0.8rem;
    }
    &__items {
      margin-top: 5px;
      @include all-center-flex;
      gap: 15px;
    }
    &__item {
      @include all-center-flex;
      gap: 5px;
    }
  }

  &__close {
    position: absolute;
    transform: translate(-50%, -50%);
    right: -10px;
    top: 30px;
    z-index: 10;
    width: 32px;
  }

  &__content {
    @include all-center-flex;
    flex-direction: column;
    color: $text-lighten;
    font-weight: 700;
    gap: 10px;
    width: 80vw;
    @include background-image-settings("/assets/popup/background-popup.png");
    background-size: 100% 100%;
    padding: 45px 28px;
    border-radius: 8px;
    text-align: center;
  }

  &__actions {
    display: flex;
    margin-top: 5px;
    justify-content: space-around;
    button {
      padding: 10px 20px;
      cursor: pointer;
    }
  }

  &__actions__settings {
    display: flex;
    height: 150px;
    flex-direction: column;
    width: 100%;
    padding: 20px;
  
    gap: 10px;
    justify-content: space-between;

    &__option {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 30px;
    }
  }

  &__btn {
    padding: 2px 12px;
    font-weight: 700;
    border-radius: 8px;
    position: relative;
    border: $border-btn-active;
    background: $radial-gradient-btn-active;
    color: white;
    text-shadow: $border-btn-text-disabled;
    &::after {
      content: "";
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 5px;
      z-index: 9;
      background: $btn-background-enabled-color;
      border: 1px solid $btn-background-enabled-beffore;
      border-radius: 4px 4px 120px 120px;
    }

    &_disabled {
      cursor: pointer;
      border: $border-blue;
      background: $radial-gradient-blue;
      color: $color-text-btn-disabled;
      text-shadow: $border-btn-text-disabled;
      &::after {
        content: "";
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 5px;
        z-index: 9;
        background: #5a6b75;
        border: 1px solid #282941;
        border-radius: 4px 4px 120px 120px;
      }
    }
  }
}



.green {
  text-shadow: $border-text-light-green;
}

.bonus__text {
  font-size: 0.5rem;
  @media (min-width: 300px) and (max-width: 350px) {
    font-size: 1rem;
  }
}

.big {
  font-size: 1rem;
  @media (min-width: 300px) and (max-width: 350px) {
    font-size: 1.5rem;
  }
}

.red {
  text-shadow: $border-text-light-red;
}

.scale{
  transform: translateY(-20px);
  transform: scale(1.15);
}