@import "src/styles/mixins.scss"; @import "src/styles/variables.scss"; @import "src/styles/media.scss";
.sale {
  &__wrapper {
    min-width: 30vw;
    min-height: 30vw;
    background-size: cover;

    @include all-center-flex;

    @media (min-width: 300px) and (max-width: 350px) {
      min-width: 50vw;
      min-height: 50vw;
    }
  }
  &__content {
    padding: 5px;
    @include all-center-flex;
    text-wrap: wrap;
    max-width: 60%;
    color: rgb(231, 235, 44);
    flex-direction: column;
  }
}
