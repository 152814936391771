@import "src/styles/mixins.scss"; @import "src/styles/variables.scss"; @import "src/styles/media.scss";
.wrapper {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 9999;
  gap: 20px;
  @include all-center-flex;
  @include background-image-settings("/assets/screens/drop/drop_ui.jpg");
  background-size: 100% 100%;
  flex-direction: column;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
  }

  > * {
    position: relative;
    z-index: 2;
  }
}

.loader {
  width: 80%;
  height: 40px;
  @include all-center-flex;
  @include background-image-settings(
    "/assets/screens/loader/loader_wrapper.png"
  );
  background-size: 100% 100%;
  position: relative;
  justify-content: flex-start;
  padding: 8px 15px 12px 15px;
  transition: 0.5s linear all;

  &__inner__wrapper {
    display: flex;
    height: 90%;
    width: 100%;
  }

  &__inner {
    transition: 0.5s linear all;
    height: 100%;
  }

  &__head {
    position: absolute;
    left: 0;
    transform: translateX(0);
  }

  &__title {
    color: $color-premium;
    font-weight: 900;
    font-size: 2rem;
    text-shadow: $border-text-gold-2x;
  }
}
